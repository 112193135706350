import { Tooltip as MantineTooltip } from "@mantine/core";
import * as classes from "./Tooltip.css";

export function Tooltip(props: React.ComponentProps<typeof MantineTooltip>) {
  return (
    <MantineTooltip
      {...props}
      classNames={{
        tooltip: classes.tooltip,
      }}
    />
  );
}
